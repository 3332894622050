import React from 'react';
import Head from 'next/head';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { fetcher } from 'api/fetcher';
import { SWRConfig } from 'swr';
import theme from 'theme';
import '@fontsource/dm-serif-text';
import 'focus-visible/dist/focus-visible';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Analytics } from '@vercel/analytics/react';

dayjs.extend(utc);

export type NextPageWithLayout<T = {}> = NextPage<T> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<T = {}> = AppProps & {
  Component: NextPageWithLayout<T>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <React.Fragment>
      <Head>
        <title>Forest Lagoon | Booking</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#43a39d" />
        <meta
          property="og:title"
          content="Forest Lagoon Booking"
          key="og-title"
        />
        <meta
          name="description"
          content="Forest Lagoon is a new geothermal spa located in Vaðlaskógur forest, couple minutes' car drive from Akureyri."
          key="description"
        />
        <meta
          name="og:description"
          content="Forest Lagoon is a new geothermal spa located in Vaðlaskógur forest, couple minutes' car drive from Akureyri."
          key="og-description"
        />
        <meta property="og:site_name" content="Forest Lagoon Booking" />
        <meta property="og:image" content="/assets/og.webp" key="og-image" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content="/assets/og.jpg"
          key="og-image"
        />
      </Head>

      <ChakraProvider resetCSS theme={theme}>
        <SWRConfig
          value={{
            fetcher,
          }}
        >
          {getLayout(<Component {...pageProps} />)}
        </SWRConfig>
      </ChakraProvider>
      <Analytics />
    </React.Fragment>
  );
}

export default App;
