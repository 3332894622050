import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: 'forest.400',
  },
};

const theme = extendTheme({
  fonts: {
    heading: `'DM Serif Text', serif`,
    body: `'DM Serif Text', serif`,
  },
  colors: {
    forest: {
      50: '#e0fbf9',
      100: '#c2ebe8',
      200: '#a1dbd6',
      300: '#7ecbc6',
      400: '#5cbcb6',
      500: '#43a39d',
      600: '#317f7a',
      700: '#1f5b57',
      800: '#0b3735',
      900: '#001512',
    },
    sky: {
      50: '#defdff',
      100: '#b3f2ff',
      200: '#86e8fd',
      300: '#5be0fc',
      400: '#3ed7fb',
      500: '#32bee2',
      600: '#2293b0',
      700: '#136a7e',
      800: '#00404d',
      900: '#00161d',
    },
  },
  shadows: {
    outline: '0 0 0 3px var(--chakra-colors-forest-200)',
  },
  components: {
    Input,
  },
});

export default theme;
